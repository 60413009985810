import React from "react";
import { HiArrowNarrowRight } from "react-icons/hi";
import cecel from "../assets/cecel.jpeg";
import { Link } from "react-scroll";
const Home = () => {
  return (
    <div name="home" className="w-full h-screen bg-[#0a192f]">
      {/* Container */}
      <div className="max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full">
        <p className="mt-24 text-xl text-cyan-300">Hi, my name is</p>
        <h1 className="text-4xl sm:text-7xl font-bold z-10 text-[#ccd6f6]">
          MARCEL REZENDE
        </h1>
        <h2 className="text-3xl md:text-4xl sm:text-7xl mb-4 z-10 font-bold text-[#98a1be]">
          I'm a Front-End Web Developer.
        </h2>
        <div className="opacity-80 sm:absolute sm:flex sm:items-end right-10 ">
          <img
            src={cecel}
            alt="Marcel Pic"
            className="rounded-lg sm:rounded-full"
          />
        </div>
        <p className="text-[#8892b0] py-4 max-w-[500px]">
          I’m specializing in building amazing web page layouts and
          applications. I'm currently searching for my next big step in my
          Front-End journey to keep improving and creating great web pages and
          applications.
        </p>
        <div>
          <Link to="projects" smooth={true} duration={800}>
            <button className="flex items-center px-6 py-3 my-2 text-white border-2 group hover:bg-cyan-400 hover:border-cyan-400">
              View projects
              <span className="duration-300 group-hover:rotate-90">
                <HiArrowNarrowRight className="ml-3 " />
              </span>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;
