import React from "react";

const About = () => {
  return (
    <div name="about" className="w-full h-screen bg-[#0a192f] text-gray-300">
      <div className="flex flex-col items-center justify-center w-full h-full">
        <div className="max-w-[1000px] w-full grid grid-cols-2 gap-8">
          <div className="pb-8 pl-4">
            <p className="inline text-4xl font-bold border-b-4 border-cyan-300">
              About
            </p>
          </div>
          <div></div>
        </div>
        <div className="max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4">
          <div className="text-4xl font-bold ">
            <p>Hello. I'm Marcel, nice to meet you.</p>
          </div>
          <div className="flex flex-col gap-2">
            <p>
              I am a web developer with 6 years of experience. From startups to
              multinationals, I've worked on a range of projects, gaining
              valuable insights along the way.
            </p>
            <p>
              During my graduation I had the opportunity to study and intern in
              the US, which further fueled my passion for web development. With
              attention to detail and a deep understanding of the latest
              frontend technologies, I aim to deliver engaging and responsive
              web applications.
            </p>
            <p>
              Beyond my professional life, you can find me exploring the latest
              trends in web development, or diving into personal coding
              projects.
            </p>
            <p>Let's connect and collaborate to build something remarkable!</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
