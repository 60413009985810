import apex from "../assets/projects/apex.png";
import game_cat from "../assets/projects/game_cat.png";
import music_player from "../assets/projects/music_player.png";
import ecommerce from "../assets/projects/ecommerce.png";
import arantes from "../assets/projects/arantes.png";

export const data = [
  {
    id: 1,
    name: "Apex Legends Info",
    image: apex,
    github: "https://github.com/marcelrzd/apex-info",
    live: "https://apex-info-marcelrzd.vercel.app/",
  },
  {
    id: 2,
    name: "NextJS Ecommerce",
    image: ecommerce,
    github: "https://github.com/marcelrzd/next-ecommerce",
    live: "https://next-ecommerce-mrz.vercel.app/",
  },
  {
    id: 3,
    name: "Game Catalog",
    image: game_cat,
    github: "https://github.com/marcelrzd/game-catalog",
    live: "https://game-catalog-mrz.vercel.app/",
  },
  {
    id: 4,
    name: "React Music Player",
    image: music_player,
    github: "https://github.com/marcelrzd/react-music-player",
    live: "",
  },
  {
    id: 5,
    name: "Arantes Imóveis",
    image: arantes,
    github: "",
    live: "https://www.arantesimoveis.com/",
  },
];
