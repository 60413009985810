import React from "react";
import { data } from "../data/data.js";

const Work = () => {
  // projects file
  const project = data;
  //setProject(data);

  return (
    <div
      name="projects"
      className="w-full md:h-screen text-gray-300 bg-[#0a192f]"
    >
      <div className="max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full">
        <div className="pb-8">
          <p className="inline text-4xl font-bold text-gray-300 border-b-4 border-cyan-300">
            Projects
          </p>
          <p className="py-6">&#47;&#47; Check out some of my recent work</p>
        </div>

        {/* container for projects */}
        <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-3">
          {/* Gird Item */}
          {project.map((item, index) => (
            <div
              key={index}
              style={{ backgroundImage: `url(${item.image})` }}
              className="shadow-lg shadow-[#040c16] group container rounded-md 
              flex justify-center text-center items-center mx-auto content-div hover:bg-teal-600"
            >
              {/* Hover effect for images */}
              <div className="opacity-0 group-hover:opacity-100">
                <span className="text-2xl tracking-wider text-white font bold ">
                  {item.name}
                </span>
                <div className="pt-8 text-center ">
                  {/* eslint-disable-next-line */}
                  {item.github && (
                    <a href={item.github} target="_blank" rel="noreferrer">
                      <button className="px-4 py-3 m-2 text-lg font-bold text-center text-gray-700 transition-all duration-500 ease-in-out bg-white rounded-lg hover:text-white hover:bg-cyan-600">
                        Code
                      </button>
                    </a>
                  )}
                  {/* eslint-disable-next-line */}
                  {item.live && (
                    <a href={item.live} target="_blank" rel="noreferrer">
                      <button className="px-4 py-3 m-2 text-lg font-bold text-center text-gray-700 transition-all duration-500 ease-in-out bg-white rounded-lg hover:text-white hover:bg-cyan-500">
                        Live
                      </button>
                    </a>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Work;
