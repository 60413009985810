import React, { useState } from "react";
import { FaGithub, FaLinkedin } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { BsFillPersonLinesFill } from "react-icons/bs";
import Logo from "../assets/logo.png";
import { Link } from "react-scroll";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);

  return (
    <div className="fixed w-full z-20 h-[80px] flex justify-between items-center px-4 bg-[#0a192f] text-gray-300">
      <div>
        <Link to="home" className="cursor-pointer" smooth={true} duration={800}>
          <img src={Logo} alt="Logo" style={{ width: "120px" }} />
        </Link>
      </div>

      {/* menu */}
      <ul className="hidden md:flex ">
        <li className="transition-colors duration-500 ease-in-out hover:text-cyan-400">
          <Link to="home" smooth={true} duration={800}>
            Home
          </Link>
        </li>
        <li className="transition-colors duration-500 ease-in-out hover:text-cyan-400">
          <Link to="about" smooth={true} duration={800}>
            About
          </Link>
        </li>
        <li className="transition-colors duration-500 ease-in-out hover:text-cyan-400">
          <Link to="skills" smooth={true} duration={800}>
            Skills
          </Link>
        </li>
        <li className="transition-colors duration-500 ease-in-out hover:text-cyan-400">
          <Link to="projects" smooth={true} duration={800}>
            Projects
          </Link>
        </li>
      </ul>

      {/* Hamburger */}
      <div className="z-10 cursor-pointer md:hidden">
        <label className="btn btn-circle swap swap-rotate text-white/50 bg-[#0a192f] hover:bg-[#0a192f] border-none">
          {/* this hidden checkbox controls the state */}
          <input
            type="checkbox"
            onClick={handleClick}
            checked={!nav ? false : true}
          />

          {/* hamburger icon */}
          <svg
            className="fill-current swap-off"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 512 512"
          >
            <path d="M64,384H448V341.33H64Zm0-106.67H448V234.67H64ZM64,128v42.67H448V128Z" />
          </svg>

          {/* close icon */}
          <svg
            className="fill-current swap-on"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 512 512"
          >
            <polygon points="400 145.49 366.51 112 256 222.51 145.49 112 112 145.49 222.51 256 112 366.51 145.49 400 256 289.49 366.51 400 400 366.51 289.49 256 400 145.49" />
          </svg>
        </label>
      </div>

      {/* Mobile menu */}
      <ul
        className={`transition-all ease-in duration-1000 bg-[#0a192f]
          ${
            !nav
              ? "hidden "
              : "absolute top-0 left-0 w-full h-screen flex flex-col justify-center items-center"
          }`}
      >
        <li className="py-6 text-4xl">
          <Link onClick={handleClick} to="home" smooth={true} duration={800}>
            Home
          </Link>
        </li>
        <li className="py-6 text-4xl">
          {" "}
          <Link onClick={handleClick} to="about" smooth={true} duration={800}>
            About
          </Link>
        </li>
        <li className="py-6 text-4xl">
          {" "}
          <Link onClick={handleClick} to="skills" smooth={true} duration={800}>
            Skills
          </Link>
        </li>
        <li className="py-6 text-4xl">
          {" "}
          <Link
            onClick={handleClick}
            to="projects"
            smooth={true}
            duration={800}
          >
            Projects
          </Link>
        </li>
        <li className="py-6 text-4xl">
          {" "}
          <Link onClick={handleClick} to="contact" smooth={true} duration={800}>
            Contact
          </Link>
        </li>
      </ul>

      {/* Social icons */}
      <div className="hidden md:flex fixed flex-col top-[35%] left-0">
        <ul>
          <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-blue-600">
            <a
              className="flex items-center justify-between w-full text-gray-300"
              href="https://linkedin.com/in/rzdmarcel"
              target="_blank"
              rel="noreferrer"
            >
              Linkedin <FaLinkedin size={30} />
            </a>
          </li>
          <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#333333]">
            <a
              className="flex items-center justify-between w-full text-gray-300"
              href="https://github.com/marcelrzd"
              target="_blank"
              rel="noreferrer"
            >
              Github <FaGithub size={30} />
            </a>
          </li>
          <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-cyan-600">
            <a
              className="flex items-center justify-between w-full text-gray-300"
              href="mailto:marcel.rzd@gmail.com"
            >
              Email <HiOutlineMail size={30} />
            </a>
          </li>
          <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#565f69]">
            <a
              className="flex items-center justify-between w-full text-gray-300"
              target="_blank"
              rel="noreferrer"
              href="https://drive.google.com/file/d/1GMWTSpk7AU_oZjCq48q1FVA8XcGYOH9t/view?usp=sharing"
            >
              Resume <BsFillPersonLinesFill size={30} />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
