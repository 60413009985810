import React from "react";
import { BsFillPersonLinesFill } from "react-icons/bs";
import { FaGithub, FaLinkedin } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";

const Contact = () => {
  return (
    <div
      name="contact"
      className="w-full h-screen md:hidden bg-[#0a192f] text-gray-300"
    >
      <div className="max-w-[1000px] mx-auto p-4  flex flex-col justify-center w-full h-full">
        <div>
          <p className="inline text-4xl font-bold border-b-4 border-cyan-300 ">
            Contact
          </p>
          <p className="py-4">
            &#47;&#47; Here is my resume and the places you can contact me
          </p>
        </div>
        <div className="grid w-full grid-cols-2 gap-4 py-8 text-center sm:grid-cols-4">
          <div className="bg-blue-700 rounded-md pt-4 shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://linkedin.com/in/rzdmarcel"
            >
              <div className="flex items-center justify-center">
                <FaLinkedin size={60} />
              </div>
              <p className="my-4">Linkedin</p>
            </a>
          </div>
          <div className="rounded-md shadow-md bg-[#333333] pt-4 shadow-[#040c16] hover:scale-110 duration-500 ">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://github.com/marcelrzd"
            >
              <div className="flex items-center justify-center">
                <FaGithub size={60} />
              </div>
              <p className="my-4">GitHub</p>
            </a>
          </div>
          <div className="bg-cyan-600 rounded-md pt-4 shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <a
              target="_blank"
              rel="noreferrer"
              href="mailto:marcel.rzd@gmail.com"
            >
              <div className="flex items-center justify-center">
                <HiOutlineMail size={60} />
              </div>
              <p className="my-4">Email</p>
            </a>
          </div>
          <div className="rounded-md pt-4 bg-[#565f69] shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://drive.google.com/file/d/1GMWTSpk7AU_oZjCq48q1FVA8XcGYOH9t/view?usp=sharing"
            >
              <div className="flex items-center justify-center">
                <BsFillPersonLinesFill size={60} />
              </div>
              <p className="my-4">Resume</p>
            </a>
          </div>
        </div>
      </div>
      {/* <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
        <img className="w-20 mx-auto" src={CSS} alt="CSS icon" />
        <p className="my-4">CSS</p>
      </div>
      <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
        <img className="w-20 mx-auto" src={JavaScript} alt="JS icon" />
        <p className="my-4">JAVASCRIPT</p>
      </div>
      <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
        <img className="w-20 mx-auto" src={ReactImg} alt="React icon" />
        <p className="my-4">REACT</p>
      </div>
      <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
        <img className="w-20 mx-auto" src={GitHub} alt="GitHub icon" />
        <p className="my-4">GITHUB</p>
      </div>

      <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
        <img className="w-20 mx-auto" src={Tailwind} alt="Tailwind icon" />
        <p className="my-4">TAILWIND CSS</p>
      </div> */}
    </div>
  );
};

export default Contact;
